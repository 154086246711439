ul.bxslider {
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
}
ul.bxslider .gallery-item {
  display: inline-flex;
}
ul.bxslider .gallery-item .elevatezoom-gallery {
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.modal-wrapper {
  width: 100%;
  display: flex;
  height: 500px;
}
.modal-left-content {
  flex: 12 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-img-full-view {
  max-height: 450px;
}
.modal-right-content {
  flex: 6 0 0;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}
.modal-product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.modal-product-title {
   font-size: 16px;
  color: #000000;
  line-height: 24px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.modal-product-item {
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: border 0.5s;
}
.modal-product-item.active {
  border-color: #1677ff;
}
.wpp-modal-dialog .ant-modal-close-icon {
   position: relative;
   top: -3px;
}